/* eslint no-console:0 */

// Rails functionality
import Rails from "@rails/ujs";

// Make accessible for Electron and Mobile adapters
window.Rails = Rails;

// require("@rails/activestorage").start();
// import "@rails/actiontext";

// Start Rails UJS
Rails.start();
